/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/dot-notation */

import Config from 'react-native-config'

/*
  In the newer version of NX, they removed internal support
  for env variables to react-native in favor of letting developers
  choose what they wanted. https://github.com/nrwl/nx/issues/19268

  That in turn broke our release builds.

  To make the env variables work with react-native they were split into
  a native config to leverage they react native specific way to
  pull into environment variables.

  Moving forward everyone will need a .env file in the consumer-native app with
  the right env variables. This is because the react-native-config variables
  get set when they are read in via the Podfile.

*/

const CONSUMER_API_URL = Config['CONSUMER_API_URL'] ?? ''
const WS_URL = Config['WS_URL'] ?? ''
const CONSUMER_API_KEY = Config['CONSUMER_API_KEY'] ?? ''
const CONSUMER_API_MIDDLEWARE_URL = Config['CONSUMER_API_MIDDLEWARE_URL'] ?? ''

// Consumer api url for articles, if not set defaults to base
const CONSUMER_API_URL_ARTICLES = Config['CONSUMER_API_URL_ARTICLES_ONLY'] ?? CONSUMER_API_URL
const FANTASY_RANKINGS = `${CONSUMER_API_URL}/v1/fantasy/rankings`

// Sping Environment ID from process, Default value ?
const SPRIG_ENV_ID = Config['SPRIG_ENV_ID'] ?? '8DGDao9_orOj'

const YEARLY_SUB = Config['YEARLY_SUB'] ?? ''
const MONTHLY_SUB = Config['MONTHLY_SUB'] ?? ''

const AF_MOBILE_KEY = Config['AF_MOBILE_KEY'] ?? ''
const AF_APP_ID = Config['AF_APP_ID'] ?? ''

const GOOGLE_CLIENT_ID = Config['GOOGLE_CLIENT_ID'] ?? ''
const FACEBOOK_CLIENT_ID = Config['FACEBOOK_CLIENT_ID'] ?? ''

const ANALYTICS_DAY7 = Config['ANALYTICS_DAY7'] ?? 7
const ANALYTICS_DAY14 = Config['ANALYTICS_DAY14'] ?? 14
const ANALYTICS_DAY21 = Config['ANALYTICS_DAY21'] ?? 21

const LOGIN_URL = Config['LOGIN_URL'] ?? ''

const GEO_DOMAIN = Config['GEO_DOMAIN'] ?? 'https://geo.pffstaging.com'
const PFF_DOMAIN = Config['PFF_DOMAIN'] ?? 'https://www.pffstaging.com'
const MERLIN_URL = Config['MERLIN_URL'] ?? 'https://www.pffstaging.com'

const PROXY_ENABLED = !!Config['PROXY_ENABLED']

const LD_MOBILE_KEY = Config['LD_MOBILE_KEY'] ?? ''
const NEXT_PUBLIC_LD_CLIENT_SIDE_ID = Config['NEXT_PUBLIC_LD_CLIENT_SIDE_ID'] ?? ''
const LD_CLIENT_SIDE_ID = Config['NEXT_PUBLIC_LD_CLIENT_SIDE_ID'] || Config['LD_CLIENT_SIDE_ID'] || ''
const LD_SDK_KEY = Config['LD_SDK_KEY'] ?? ''

const NX_HEAP_APP_ID = Config['NX_HEAP_APP_ID'] ?? ''

const AIRSHIP_APP_KEY = Config['AIRSHIP_APP_KEY'] ?? ''
const AIRSHIP_APP_SECRET = Config['AIRSHIP_APP_SECRET'] ?? ''

const DISABLE_SENTRY_ROUTING_METRICS = Config['DISABLE_SENTRY_ROUTING_METRICS'] ?? false
const SENTRY_ENVIRONMENT = Config['SENTRY_ENVIRONMENT'] ?? ''

export const envConfig = {
  CONSUMER_API_URL,
  CONSUMER_API_MIDDLEWARE_URL,
  CONSUMER_API_KEY,
  CONSUMER_API_URL_ARTICLES,
  FANTASY_RANKINGS,
  SPRIG_ENV_ID,
  YEARLY_SUB,
  MONTHLY_SUB,
  AF_MOBILE_KEY,
  AF_APP_ID,
  GOOGLE_CLIENT_ID,
  FACEBOOK_CLIENT_ID,
  ANALYTICS_DAY7,
  ANALYTICS_DAY14,
  ANALYTICS_DAY21,
  LOGIN_URL,
  WS_URL,
  GEO_DOMAIN,
  PFF_DOMAIN,
  PROXY_ENABLED,
  LD_MOBILE_KEY,
  NEXT_PUBLIC_LD_CLIENT_SIDE_ID,
  LD_CLIENT_SIDE_ID,
  LD_SDK_KEY,
  NX_HEAP_APP_ID,
  AIRSHIP_APP_KEY,
  AIRSHIP_APP_SECRET,
  DISABLE_SENTRY_ROUTING_METRICS,
  SENTRY_ENVIRONMENT,
  MERLIN_URL,
}
