export type NFLWeekStatus = 'Upcoming' | 'Current' | 'Finished'

export enum NFLWeekAbbreviation {
  PreseasonWeek4 = 'P4',
  PreseasonWeek3 = 'P3',
  PreseasonWeek2 = 'P2',
  PreseasonWeek1 = 'P1',
  HallOfFame = 'HF',
  Week1 = '1',
  Week2 = '2',
  Week3 = '3',
  Week4 = '4',
  Week5 = '5',
  Week6 = '6',
  Week7 = '7',
  Week8 = '8',
  Week9 = '9',
  Week10 = '10',
  Week11 = '11',
  Week12 = '12',
  Week13 = '13',
  Week14 = '14',
  Week15 = '15',
  Week16 = '16',
  Week17 = '17',
  Week18 = '18',
  Week28 = '28',
  Week29 = '29',
  Week30 = '30',
  Week32 = '32',
  Wildcard = 'WC',
  DivisionalPlayoff = 'DP',
  ConferenceChampionship = 'CC',
  SuperBowl = 'SB',
}

export interface NFLWeekTypes {
  PRE: 'Preseason'
  REG: 'Regular'
  POST: 'Postseason'
  PRO: 'ProBowl'
  OFF: 'Offseason'
  OTHER: 'Other'
}

export type NFLWeekTypeKey = keyof NFLWeekTypes

export type NFLWeekTypeValue = NFLWeekTypes[keyof NFLWeekTypes]

export interface NFLWeek {
  weekType: NFLWeekTypeKey
  weekValue: number
  description: NFLWeekTypeValue
  status?: NFLWeekStatus
  season?: number
}

export const NFL_WEEK_TYPES: NFLWeekTypes = Object.freeze({
  PRE: 'Preseason',
  REG: 'Regular',
  POST: 'Postseason',
  PRO: 'ProBowl',
  OFF: 'Offseason',
  OTHER: 'Other',
})

export enum NFLWeekTypeEnum {
  Regular = 'regular-season',
  Pre = 'pre-season',
  Post = 'post-season',
}

export enum WarehouseWeekTypeMap {
  'regular-season' = 'REG',
  'pre-season' = 'PRE',
  'post-season' = 'POST',
  'non-season' = 'NON',
}

export const PostSeasonWeeksMap: Partial<Record<NFLWeekAbbreviation, string>> = {
  28: 'Wild Card',
  WC: 'Wild Card',
  29: 'Divisional Playoff',
  DP: 'Divisional Playoff',
  30: 'Conf. Championship',
  CC: 'Conf. Championship',
  32: 'Super Bowl',
  SB: 'Super Bowl',
}

export interface SDKWeek {
  weekType: NFLWeekTypeKey
  abbreviation: NFLWeekAbbreviation
  description: string
  status: string
  season: number
}

export const NFLPostSeasonWeeks = <const>{
  WC: 'Week 1',
  DP: 'Week 2',
  CC: 'Week 3',
  SB: 'Week 4',
}
