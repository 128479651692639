import { NCAASvgMap } from '../../ncaa-svg-map'
import { FootballTeam } from '../football-team'

export enum NCAATeamAbbreviation {
  ABICHR = 'ABICHR',
  AIRFOR = 'AIRFOR',
  AKRONZ = 'AKRONZ',
  ALABAM = 'ALABAM',
  ALABST = 'ALABST',
  ALBANY = 'ALBANY',
  ALBSTA = 'ALBSTA',
  ALCOST = 'ALCOST',
  ALDBRO = 'ALDBRO',
  AMRINT = 'AMRINT',
  ANGST = 'ANGST',
  ANSELM = 'ANSELM',
  APPSTA = 'APPSTA',
  ARIZON = 'ARIZON',
  ARKANS = 'ARKANS',
  ARKAPB = 'ARKAPB',
  ARKBAP = 'ARKBAP',
  ARKSTA = 'ARKSTA',
  ARMYBK = 'ARMYBK',
  ASSMPT = 'ASSMPT',
  ASUSUN = 'ASUSUN',
  AUBURN = 'AUBURN',
  AUGSBU = 'AUGSBU',
  AUPEAY = 'AUPEAY',
  AURORA = 'AURORA',
  AVEMAR = 'AVEMAR',
  BACONE = 'BACONE',
  BALLST = 'BALLST',
  BAMACT = 'BAMACT',
  BARCOL = 'BARCOL',
  BAYLOR = 'BAYLOR',
  BCEAGL = 'BCEAGL',
  BENEDI = 'BENEDI',
  BETHCO = 'BETHCO',
  BETHEL = 'BETHEL',
  BLHLYJ = 'BLHLYJ',
  BLMSBG = 'BLMSBG',
  BLUEFI = 'BLUEFI',
  BOISES = 'BOISES',
  BOWIES = 'BOWIES',
  BOWLGR = 'BOWLGR',
  BROWNB = 'BROWNB',
  BRYBUL = 'BRYBUL',
  BSU = 'BSU',
  BUCKNE = 'BUCKNE',
  BUFFBU = 'BUFFBU',
  BUTBUL = 'BUTBUL',
  BYUCOU = 'BYUCOU',
  CALIFO = 'CALIFO',
  CALPA = 'CALPA',
  CALPOL = 'CALPOL',
  CAMPBE = 'CAMPBE',
  CARLET = 'CARLET',
  CARSON = 'CARSON',
  CATAWB = 'CATAWB',
  CENARK = 'CENARK',
  CENCON = 'CENCON',
  CENMIC = 'CENMIC',
  CENSTA = 'CENSTA',
  CENWAS = 'CENWAS',
  CHADST = 'CHADST',
  CHAPMA = 'CHAPMA',
  CHARLO = 'CHARLO',
  CHASOU = 'CHASOU',
  CHATTA = 'CHATTA',
  CHOWAN = 'CHOWAN',
  CINCIN = 'CINCIN',
  CITADL = 'CITADL',
  CLARKA = 'CLARKA',
  CLEMSO = 'CLEMSO',
  COASTA = 'COASTA',
  COLGAT = 'COLGAT',
  COLORA = 'COLORA',
  COLOST = 'COLOST',
  COLUMB = 'COLUMB',
  COMESA = 'COMESA',
  CONCMI = 'CONCMI',
  CONCMN = 'CONCMN',
  CONCNE = 'CONCNE',
  CORNEL = 'CORNEL',
  CUMBER = 'CUMBER',
  DARTMO = 'DARTMO',
  DAVIDS = 'DAVIDS',
  DAYFLY = 'DAYFLY',
  DELAWA = 'DELAWA',
  DELTST = 'DELTST',
  DEPATI = 'DEPATI',
  DESTAT = 'DESTAT',
  DIXIST = 'DIXIST',
  DRAKEB = 'DRAKEB',
  DUKEBD = 'DUKEBD',
  DUQUES = 'DUQUES',
  EANMGH = 'EANMGH',
  EASORE = 'EASORE',
  EASTCE = 'EASTCE',
  EASTMI = 'EASTMI',
  ECU = 'ECU',
  ECUPIR = 'ECUPIR',
  EDNBFS = 'EDNBFS',
  EDWARD = 'EDWARD',
  EILLPA = 'EILLPA',
  EKYCOL = 'EKYCOL',
  ELICIT = 'ELICIT',
  ELONPH = 'ELONPH',
  ETENNB = 'ETENNB',
  EWASHE = 'EWASHE',
  FALKNR = 'FALKNR',
  FAUOWL = 'FAUOWL',
  FAYETT = 'FAYETT',
  FERRIS = 'FERRIS',
  FIUGOP = 'FIUGOP',
  FLMMRL = 'FLMMRL',
  FLORAM = 'FLORAM',
  FLTECH = 'FLTECH',
  FORDHA = 'FORDHA',
  FORTLE = 'FORTLE',
  FPU = 'FPU',
  FRANKL = 'FRANKL',
  FRSU = 'FRSU',
  FRTVLY = 'FRTVLY',
  FSBULL = 'FSBULL',
  FSUSEM = 'FSUSEM',
  FUPALA = 'FUPALA',
  GARDNE = 'GARDNE',
  GEORKY = 'GEORKY',
  GEOTEC = 'GEOTEC',
  GNU = 'GNU',
  GRAMBL = 'GRAMBL',
  GSOUTH = 'GSOUTH',
  GSTATE = 'GSTATE',
  GTOWNH = 'GTOWNH',
  GUSTAV = 'GUSTAV',
  HA = 'HA',
  HAMLIN = 'HAMLIN',
  HAMPTO = 'HAMPTO',
  HARVAR = 'HARVAR',
  HAWAWA = 'HAWAWA',
  HBCUGA = 'HBCUGA',
  HBCURO = 'HBCURO',
  HCCRUS = 'HCCRUS',
  Hof = 'Hof',
  HOUSBP = 'HOUSBP',
  HOUSTO = 'HOUSTO',
  HOWBIS = 'HOWBIS',
  IDACOL = 'IDACOL',
  IDAHOV = 'IDAHOV',
  IDSTAT = 'IDSTAT',
  ILLINO = 'ILLINO',
  ILLSTA = 'ILLSTA',
  INCWOR = 'INCWOR',
  INDHOO = 'INDHOO',
  INDIAN = 'INDIAN',
  INWSLY = 'INWSLY',
  IOWAHA = 'IOWAHA',
  IOWAST = 'IOWAST',
  IUP = 'IUP',
  JACKDO = 'JACKDO',
  JACKST = 'JACKST',
  JASTAT = 'JASTAT',
  JCSMIT = 'JCSMIT',
  JMADIS = 'JMADIS',
  KANJAY = 'KANJAY',
  KEISER = 'KEISER',
  KENSTA = 'KENSTA',
  KENTST = 'KENTST',
  KENTUC = 'KENTUC',
  KENWES = 'KENWES',
  KESTAT = 'KESTAT',
  KSTATE = 'KSTATE',
  KTU = 'KTU',
  KYCRST = 'KYCRST',
  LAFAYE = 'LAFAYE',
  LAMCAR = 'LAMCAR',
  LANEDR = 'LANEDR',
  LATECH = 'LATECH',
  LEHIMH = 'LEHIMH',
  LENOIR = 'LENOIR',
  LHU = 'LHU',
  LIBERT = 'LIBERT',
  LIMEST = 'LIMEST',
  LINCBT = 'LINCBT',
  LINCOK = 'LINCOK',
  LINCOL = 'LINCOL',
  LIUPOS = 'LIUPOS',
  LIVING = 'LIVING',
  LNDNWD = 'LNDNWD',
  LOUCAR = 'LOUCAR',
  LOUCOL = 'LOUCOL',
  LSUTIG = 'LSUTIG',
  LVLEOP = 'LVLEOP',
  MALONE = 'MALONE',
  MARRED = 'MARRED',
  MARSHA = 'MARSHA',
  MARSHI = 'MARSHI',
  MARYLA = 'MARYLA',
  MCKEND = 'MCKEND',
  MCNEES = 'MCNEES',
  MEMPHI = 'MEMPHI',
  MER = 'MER',
  MERBEA = 'MERBEA',
  MERRIM = 'MERRIM',
  MHMRTI = 'MHMRTI',
  MIAMIF = 'MIAMIF',
  MIAMIO = 'MIAMIO',
  MICHIG = 'MICHIG',
  MIDTEN = 'MIDTEN',
  MILESG = 'MILESG',
  MINNES = 'MINNES',
  MISCOL = 'MISCOL',
  MISSST = 'MISSST',
  MITECH = 'MITECH',
  MIZSAT = 'MIZS&T', // what to do with "MIZS&T"
  MIZZOU = 'MIZZOU',
  MIZZST = 'MIZZST',
  MIZZWE = 'MIZZWE',
  MONGRI = 'MONGRI',
  MONMOU = 'MONMOU',
  MONSTA = 'MONSTA',
  MOREHE = 'MOREHE',
  MORGAN = 'MORGAN',
  MSTATE = 'MSTATE',
  MURRAY = 'MURRAY',
  MVSTDD = 'MVSTDD',
  MWSTAT = 'MWSTAT',
  NALABA = 'NALABA',
  NAUSTA = 'NAUSTA',
  NAVYMI = 'NAVYMI',
  NCSTAT = 'NCSTAT',
  NDFIGH = 'NDFIGH',
  NDSTAT = 'NDSTAT',
  NE = 'NE',
  NESTA = 'NE STA', //    Has space in the enum, but not in the key
  NEBRAS = 'NEBRAS',
  NEVWOL = 'NEVWOL',
  NEWBER = 'NEWBER',
  NEWMEX = 'NEWMEX',
  NICHOL = 'NICHOL',
  NMEXHI = 'NMEXHI',
  NMSTAT = 'NMSTAT',
  NORFOL = 'NORFOL',
  NORGRE = 'NORGRE',
  NORTHI = 'NORTHI',
  NORTHT = 'NORTHT',
  NORTHW = 'NORTHW',
  NOTRED = 'NOTRED',
  NSU = 'NSU',
  NWREDR = 'NWREDR',
  NWSTAT = 'NWSTAT',
  ODUMON = 'ODUMON',
  OHIOBO = 'OHIOBO',
  OHIOST = 'OHIOST',
  OKLAHO = 'OKLAHO',
  OKPANS = 'OKPANS',
  OKSTAT = 'OKSTAT',
  OLEMIS = 'OLEMIS',
  OSTATE = 'OSTATE',
  OUDUCK = 'OUDUCK',
  PENNST = 'PENNST',
  PIKEVI = 'PIKEVI',
  PITTPA = 'PITTPA',
  PITTST = 'PITTST',
  POINTS = 'POINTS',
  POSTAT = 'POSTAT',
  PRESBY = 'PRESBY',
  PRINCE = 'PRINCE',
  PSTEAG = 'PSTEAG',
  PURBOI = 'PURBOI',
  PVAMPA = 'PVAMPA',
  PWUC = 'PWUC',
  QUINCY = 'QUINCY',
  RICEOW = 'RICEOW',
  RICHMO = 'RICHMO',
  RMORRI = 'RMORRI',
  RNHEAG = 'RNHEAG',
  ROCKYM = 'ROCKYM',
  ROSEHU = 'ROSEHU',
  RU = 'RU',
  RUTGER = 'RUTGER',
  SACSTA = 'SACSTA',
  SAMFOR = 'SAMFOR',
  SANDIE = 'SANDIE',
  SAVSTA = 'SAVSTA',
  SCSTAT = 'SCSTAT',
  SDAU = 'SDAU',
  SDSTAT = 'SDSTAT',
  SELOUI = 'SELOUI',
  SEMIZZ = 'SEMIZZ',
  SFAUST = 'SFAUST',
  SHAWBE = 'SHAWBE',
  SHEART = 'SHEART',
  SHORTE = 'SHORTE',
  SHOUST = 'SHOUST',
  SHPHRD = 'SHPHRD',
  SHU = 'SHU',
  SIMFRA = 'SIMFRA',
  SJSTAT = 'SJSTAT',
  SMUMUS = 'SMUMUS',
  SOCTOW = 'SOCTOW',
  SOUILL = 'SOUILL',
  SOUORE = 'SOUORE',
  SOUTAH = 'SOUTAH',
  SOUTDS = 'SOUTDS',
  SOUTHA = 'SOUTHA',
  SOUTHC = 'SOUTHC',
  SOUTHD = 'SOUTHD',
  SOUTHM = 'SOUTHM',
  SOUTHU = 'SOUTHU',
  SOUWCO = 'SOUWCO',
  SRU = 'SRU',
  STANFO = 'STANFO',
  STANKN = 'STANKN',
  STAUGU = 'STAUGU',
  STETSO = 'STETSO',
  STFRAP = 'STFRAP',
  STHLSK = 'STHLSK',
  STJOHN = 'STJOHN',
  STOLAF = 'STOLAF',
  STONYB = 'STONYB',
  STTHOM = 'STTHOM',
  STTMFL = 'STTMFL',
  SWBAPT = 'SWBAPT',
  SWMINN = 'SWMINN',
  SYRACU = 'SYRACU',
  TARLST = 'TARLST',
  TAYLOR = 'TAYLOR',
  TCUHOR = 'TCUHOR',
  TEMPLE = 'TEMPLE',
  TENNES = 'TENNES',
  TENNST = 'TENNST',
  TENNTE = 'TENNTE',
  TEXAMK = 'TEXAMK',
  TEXASM = 'TEXASM',
  TEXASS = 'TEXASS',
  TEXAST = 'TEXAST',
  TEXSOU = 'TEXSOU',
  THMORE = 'THMORE',
  TOWSON = 'TOWSON',
  TRINIT = 'TRINIT',
  TROYTR = 'TROYTR',
  TUGOLD = 'TUGOLD',
  TULGRW = 'TULGRW',
  TUSCUL = 'TUSCUL',
  TUSKEG = 'TUSKEG',
  TXAMCO = 'TXAMCO',
  TXPERB = 'TXPERB',
  TXSTRS = 'TXSTRS',
  TXWSLY = 'TXWSLY',
  UABBLA = 'UABBLA',
  UCDAV = 'UC DAV', //  Has space in the enum, but not in the key
  UCFKNI = 'UCFKNI',
  UCLABR = 'UCLABR',
  UCONNH = 'UCONNH',
  UFGATO = 'UFGATO',
  UFTLEA = 'UFTLEA',
  UGABUL = 'UGABUL',
  UIU = 'UIU',
  ULLRAG = 'ULLRAG',
  ULMWAR = 'ULMWAR',
  UMAINE = 'UMAINE',
  UMASSM = 'UMASSM',
  UNALUM = 'UNALUM',
  UNCATT = 'UNCATT',
  UNCBEA = 'UNCBEA',
  UNCCEN = 'UNCCEN',
  UNCPEM = 'UNCPEM',
  UNCTAR = 'UNCTAR',
  UNHWIL = 'UNHWIL',
  UNIPAN = 'UNIPAN',
  UNLVRE = 'UNLVRE',
  UPENNQ = 'UPENNQ',
  URIRAM = 'URIRAM',
  USCTRO = 'USCTRO',
  USF = 'USF',
  USFBUL = 'USFBUL',
  UTAHS = 'UTAH S', //  Has space in the enum, but not in the key
  UTAHUT = 'UTAHUT',
  UTEPMI = 'UTEPMI',
  UTLONG = 'UTLONG',
  UTMART = 'UTMART',
  UTROCK = 'UTROCK',
  UTSARR = 'UTSARR',
  UVAWIS = 'UVAWIS',
  UWGWOL = 'UWGWOL',
  UWHUSK = 'UWHUSK',
  VALDOS = 'VALDOS',
  VALPAR = 'VALPAR',
  VANDYC = 'VANDYC',
  VATECH = 'VATECH',
  VILLAN = 'VILLAN',
  VIRGIN = 'VIRGIN',
  VIRLYN = 'VIRLYN',
  VIRMIL = 'VIRMIL',
  VIRSTA = 'VIRSTA',
  VIRUNI = 'VIRUNI',
  WABASH = 'WABASH',
  WAGNER = 'WAGNER',
  WAKEFO = 'WAKEFO',
  WARNER = 'WARNER',
  WEBINT = 'WEBINT',
  WEBSTA = 'WEBSTA',
  WECOST = 'WECOST',
  WESALA = 'WESALA',
  WESORE = 'WESORE',
  WESTCA = 'WESTCA',
  WESTFL = 'WESTFL',
  WESTIL = 'WESTIL',
  WESTMI = 'WESTMI',
  WESTVI = 'WESTVI',
  WEVIRW = 'WEVIRW',
  WHEATO = 'WHEATO',
  WHU = 'WHU',
  WILMAR = 'WILMAR',
  WINGAT = 'WINGAT',
  WISCON = 'WISCON',
  WISCSP = 'WISCSP',
  WISTOU = 'WISTOU',
  WIWHIT = 'WIWHIT',
  WJWLL = 'WJWLL',
  WKUHIL = 'WKUHIL',
  WNEWME = 'WNEWME',
  WOFFOR = 'WOFFOR',
  WSRAMS = 'WSRAMS',
  WSTATE = 'WSTATE',
  WTEXAM = 'WTEXAM',
  WVSTAT = 'WVSTAT',
  WYOMIN = 'WYOMIN',
  YALEBU = 'YALEBU',
  YOUNGS = 'YOUNGS',
}

export interface NCAATeam extends FootballTeam {
  // TODO: update conference and divisions?
  conference?:
    | 'American'
    | 'ACC'
    | 'Big 12'
    | 'Big Ten'
    | 'C-USA'
    | 'FBS Independents'
    | 'MAC'
    | 'Mountain West'
    | 'Pac-12'
    | 'SEC'
    | 'Sun Belt'
  fcsConferences?:
    | 'ASUN'
    | 'Big Sky'
    | 'ASUN-WAC'
    | 'CAA'
    | 'Big Sky'
    | 'Big South'
    | 'Big West'
    | 'FCS Independents'
    | 'Great West'
    | 'Ivy League'
    | 'MEAC'
    | 'Missouri Valley'
    | 'Northeast'
    | 'Ohio Valley'
    | 'Patriot League'
    | 'Pioneer League'
    | 'Southern Conference'
    | 'Southland Conference'
    | 'SWAC'
    | 'UAC'
  // do these exist?
  division?: 'North' | 'East' | 'South' | 'West'
  league: 'ncaa'
}

export interface NCAATeamWithLogo extends NCAATeam {
  logo: NCAASvgMap
}
