import { NFLWeekAbbreviation, PostSeasonWeeksMap } from '@pff-consumer/schema'

const defaultWeekHeaderText = {
  Rankings: 'Weekly Rankings',
  'Waiver Targets': 'Waiver Targets',
  'Start/Sit': 'Start-Sit',
}

type FantasyPage = keyof typeof defaultWeekHeaderText

export const getWeekHeaderText = (weekAbbr: NFLWeekAbbreviation | number | undefined | null, fantasyPage: string) => {
  if (weekAbbr === undefined || weekAbbr === null) {
    return defaultWeekHeaderText[fantasyPage as FantasyPage] ?? ''
  }
  const weekAbbrStr = weekAbbr.toString() as Partial<NFLWeekAbbreviation>
  return PostSeasonWeeksMap[weekAbbrStr]
    ? `${PostSeasonWeeksMap[weekAbbrStr]} ${fantasyPage}`
    : `Week ${weekAbbr} ${fantasyPage}`
}
