import { NFLPostSeasonWeeks } from '@pff-consumer/schema'
import { strIsWeekAbv } from './str-is-week-abv'

export const formatWeekText = (currentWeek: string, description: string) => {
  if (!strIsWeekAbv(currentWeek)) {
    return description
  }

  if (currentWeek in NFLPostSeasonWeeks) {
    return `${currentWeek} ${description}`
  }
  return `${description} ${currentWeek}`
}
