export type NCAAWeekStatus = 'Upcoming' | 'Current' | 'Finished'

export enum NCAAWeekAbbreviation {
  Week0 = '0',
  Week1 = '1',
  Week2 = '2',
  Week3 = '3',
  Week4 = '4',
  Week5 = '5',
  Week6 = '6',
  Week7 = '7',
  Week8 = '8',
  Week9 = '9',
  Week10 = '10',
  Week11 = '11',
  Week12 = '12',
  Week13 = '13',
  Week14 = '14',
  Week15 = '15',
  Week16 = '16',
  ConferenceChampionship = 'CC',
  BowlGames = 'BG',
  Playoffs = 'PO',
  Championship = 'CH',
  AllStarGames = 'ASG',
  AllStarPractice = 'ASP',
}
export interface NCAAWeekTypes {
  // TODO: confirm if these are correct for NCAA
  REG: 'Regular'
  POST: 'Postseason'
  OFF: 'Offseason'
  OTHER: 'Other'
}

export type NCAAWeekTypeKey = keyof NCAAWeekTypes

export type NCAAWeekTypeValue = NCAAWeekTypes[keyof NCAAWeekTypes]

export interface NCAAWeek {
  weekType: NCAAWeekTypeKey
  weekValue: number
  description: NCAAWeekTypeValue
  status?: NCAAWeekTypeKey
  season?: number
}

export const NCAA_WEEK_TYPES: NCAAWeekTypes = Object.freeze({
  REG: 'Regular',
  POST: 'Postseason',
  OFF: 'Offseason',
  OTHER: 'Other',
})

export enum NCAAWeekTypeEnum {
  Regular = 'regular-season',
  Post = 'post-season',
}

export enum WarehouseNCAAWeekTypeMap {
  'regular-season' = 'REG',
  'post-season' = 'POST',
}

export const PostSeasonNCAAWeeksMap: Partial<Record<NCAAWeekAbbreviation, string>> = {
  CC: 'Conference Championship',
  BG: 'Bowl Games',
  PO: 'Playoffs',
  CH: 'Championship',
  ASG: 'All Star Games',
  ASP: 'All Star Practice',
}

export interface SDKNCAAWeek {
  weekType: NCAAWeekTypeKey
  abbreviation: NCAAWeekAbbreviation
  description: string
  status: string
  season: number
}
