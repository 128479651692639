export const getAssetsPath = (asset?: string): string => {
  const ASSET_PATH = process.env.NEXT_PUBLIC_ASSET_PATH
  const isProduction = process.env.NODE_ENV === 'production'
  const subPath = asset || ''
  if (ASSET_PATH) {
    return ASSET_PATH + subPath
  }
  if (isProduction) {
    return `/voodoo-assets/${subPath}`
  }
  return `/assets/${subPath}`
}
