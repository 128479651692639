import { createContext } from 'react'

interface AnimatedContextInterface {
  hasAnimated: boolean
}

const defaultValue = {
  hasAnimated: false,
}

export const AnimatedContext = createContext<AnimatedContextInterface>(defaultValue)
