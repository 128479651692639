import { PostSeasonWeeksMap } from '@pff-consumer/schema'
import { strIsWeekAbv } from './str-is-week-abv'

export const getWeekDescriptionFromWeekAbv = (weekAbv: string, location?: string): string => {
  if (!weekAbv) return ''
  const isPostWeek = Object.keys(PostSeasonWeeksMap).includes(weekAbv)
  const isFeedBestBetLocation = location === 'feed-best-bets'

  if (isFeedBestBetLocation && isPostWeek) return ''

  const postSeasonDescription = isPostWeek && strIsWeekAbv(weekAbv) ? PostSeasonWeeksMap[weekAbv] : undefined

  return postSeasonDescription ?? `Week ${weekAbv}`
}
