import { createContext } from 'react'

interface PlayerProfileInterface {
  isPremiumSubscriber?: boolean
  onPaywallCtaClick: () => void
}

const defaultProviderValue = {
  isPremiumSubscriber: false,
  onPaywallCtaClick: () => {},
}

export const PlayerProfileContext = createContext<PlayerProfileInterface>(defaultProviderValue)
